<template>
  <div class="orders">
    <div class="orders-title">
      {{ $t('views.orders.title') }}
    </div>
    <custom-select
      v-model="status"
      :items="itemsSelectByStatus"
      class="filter-select mr-4"
      :placeholder="this.$t('components.wallet.filters.items_select_by_status.placeholder')"
      @change="fetchByFilters"
    />
    <div
      v-if="hasOrders"
      class="cards-orders"
    >
      <card-order
        v-for="order in getOrders"
        :key="order.id"
        :order="order"
      />
    </div>
    <div
      v-else
      class="orders-placeholder"
    >
      {{ $t('views.orders.placeholder') }}
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import CardOrder from '@/components/cards/orders/CardOrder.vue';
import CustomSelect from '@/components/selects/CustomSelect.vue';

export default {
  name: 'Orders',
  components: {
    CardOrder,
    CustomSelect,
  },
  data() {
    return {
      itemsSelectByStatus: [
        {
          text: this.$t('views.orders.filters.pending'),
          value: 'pending',
        },
        {
          text: this.$t('views.orders.filters.paid'),
          value: 'paid',
        },
        {
          text: this.$t('views.orders.filters.all'),
          value: null,
        },
      ],
      status: null,
    };
  },
  computed: {
    ...mapGetters([
      'getOrders',
      'getBusiness',
    ]),
    hasOrders() {
      return this.getOrders.length > 0;
    },
  },
  mounted() {
    this.fetchOrders({ business_id: this.getBusiness.id });
  },
  methods: {
    ...mapActions(['fetchOrders']),

    fetchByFilters() {
      this.fetchOrders({ by_status: this.status, business_id: this.getBusiness.id });
    },
  },
};

</script>
<style lang="scss" scoped>
  .orders {
    background-color: $app_background;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding: 0 2em 0 16em;

    .orders-title {
      font-size: 1.5em;
      font-weight: bold;
      margin: 70px 0 40px 0;
      text-align: center;
    }

    .filter-select {
      width: 300px;
    }

    .orders-placeholder {
      text-align: center;
      font-size: 1.3em;
    }
  }

</style>
