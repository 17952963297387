<template>
  <div class="card-order">
    <v-card
      class="card-order-content"
      elevation="2"
    >
      <div class="order-info">
        {{ $t('components.card_order.order',{ id: order.id }) }}
      </div>
      <div class="order-info">
        {{ $t('components.card_order.status') }}
        <div class="info-content">
          {{ order.status_text }}
        </div>
      </div>
      <div class="order-info">
        {{ $t('components.card_order.created_at') }}
        <div class="info-content">
          {{ order.created_at }}
        </div>
      </div>
      <div class="order-info">
        {{ $t('components.card_order.total_value') }}
        <div class="info-content">
          {{ formattedTotalValue }}
        </div>
      </div>
      <div>
        <rectoplus-button-outlined
          class="order-button"
          :text="$t('components.card_order.show')"
          :disabled="orderCancelled"
          @click="buttonAction"
        />
      </div>
    </v-card>
  </div>
</template>
<script>
import RectoplusButtonOutlined from '@/components/buttons/RectoplusButtonOutlined.vue';
import formatMoney from '@/mixins/formatMoney';

export default {
  name: 'CardOrder',
  components: { RectoplusButtonOutlined },
  mixins: [formatMoney],
  props: {
    order: {
      type: Object,
      default: () => {},
    },
    certificates: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    formattedTotalValue() {
      return this.formatMoney(Number(this.order.total_value));
    },

    orderCancelled() {
      return this.order.status === 'cancelled';
    },
  },
  methods: {
    orderShow() {
      this.$router.push({ name: 'order', params: { id: this.order.id } });
    },

    showCertificate() {
      window.open(this.order.certify.url);
    },

    buttonAction() {
      if (this.certificates) {
        this.showCertificate();
      } else {
        this.orderShow();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
  .card-order {
    padding: 0 0 20px 0;

    .card-order-content {
      font-size: 1em;
      display: flex;
      align-items: center;
      border-radius: 10px;
      height: 100px;
      justify-content: space-between;
      padding: 0 20px 0 20px;

      .order-info {
        font-weight: bold;

        .info-content {
          font-weight: 100;
        }
      }

      .order-button {
        font-size: 1em;
        height: 50px;
        width: 200px;
      }
    }
  }

</style>
